@charset "UTF-8";
/*

Name: MadGeekMedia.com
URI: 
Description: This site was developed by Weston Digital Media for ***. Copyright 20**.
Author: Chris Weston

*/

/**************************************************


General Page Rules


**************************************************/

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 1em;
}

h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
a {
}

h1,
.h1 {
}

h2,
.h2 {
}

h3,
.h3 {
}

h4,
.h4 {
}

p,
ul,
ol,
li,
a {
}

/**************************************************


Class Rules


**************************************************/

/********************* HEADER *********************/

header h1 {
    text-align: center;
    font-size: 4rem;
}

#mainLogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

#mainLogo img {
    max-height: 80vh;
    width: auto;
}
